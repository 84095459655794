import React, {useEffect} from "react";
import Root from "templates/Root";
import styled from "styled-components";
import PageTitle from "components/PageTitle";
import { useTranslation } from "react-i18next";
import HomeIcon from "@material-ui/icons/Home";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BusinessIcon from "@material-ui/icons/Business";
import { renderHrefAttributeValue } from "helpers/renderRedirectLink";
import LocationMap from "components/LocationMap";

const StyledWrapper = styled.div`
  margin: 0 30px;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    & > * {
      flex-basis: 50%;
    }
  }
`;

const StyledContactListItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin: 20px 0;
  }
`;

const StyledContactListItemValue = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: 7px;
`;

const StyledContactListItemLink = styled.a`
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledList = styled.ul`
  list-style-type: none;
`;

const StyledContactWrapper = styled.div`
  margin-right: 20px;
`;

const StyledLocationMapWrapper = styled.div`
  height: 264px;
  margin-top: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`;

const Contact = () => {
  useEffect(() => window.scrollTo(0,0), [])
  const { t } = useTranslation();

  const contactList = [
    {
      value: "ul. Parcele 57, 09-408 Płock",
      isLink: true,
      link:
        "https://www.google.com/maps/place/Parcele+57,+09-408+P%C5%82ock/@52.5249458,19.7847041,17z/data=!3m1!4b1!4m5!3m4!1s0x471c7169fafaaf87:0x423e6d6592506264!8m2!3d52.5249458!4d19.7868928",
      icon: <HomeIcon color="primary" />,
    },
    {
      value: "psrbhp@psrbhp.pl",
      isLink: true,
      linkPrefix: "mailto:",
      icon: <EmailIcon color="primary" />,
    },
    {
      value: "+48 502 382 307",
      isLink: true,
      linkPrefix: "tel:",
      icon: <PhoneIcon color="primary" />,
    },
    {
      value: "PEKAO S.A. 75 1240 3174 1111 0010 4041 9207",
      icon: <AccountBalanceIcon color="primary" />,
    },
    {
      name: `${t("page_footer.regon")}:`,
      value: "143014847 (REGON)",
      icon: <BusinessIcon color="primary" />,
    },
    {
      name: `${t("page_footer.nip")}:`,
      value: "7743203773 (NIP)",
      icon: <BusinessIcon color="primary" />,
    },
  ];
  return (
    <Root>
      <StyledWrapper>
        <PageTitle title={t("page_nav.contact")} />
        <StyledContentWrapper>
          <StyledContactWrapper>
            <StyledList>
              {contactList.map((item) => (
                <StyledContactListItem>
                  {item.icon}
                  <StyledContactListItemValue>
                    {item.isLink ? (
                      <StyledContactListItemLink
                        href={renderHrefAttributeValue(item)}
                      >
                        {item.value}
                      </StyledContactListItemLink>
                    ) : (
                      item.value
                    )}
                  </StyledContactListItemValue>
                </StyledContactListItem>
              ))}
            </StyledList>
          </StyledContactWrapper>
          <StyledLocationMapWrapper>
            <LocationMap />
          </StyledLocationMapWrapper>
        </StyledContentWrapper>
      </StyledWrapper>
    </Root>
  );
};

export default Contact;
