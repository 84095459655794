import i18n from "plugins/i18n";

export const voivodeships = [
  {
    name: "DOLNOS",
    label: i18n.t("pages.experts_list.voivodeships.dolnoslaskie"),
  },
  {
    name: "KUJ-POM",
    label: i18n.t("pages.experts_list.voivodeships.kujawsko-pomorskie"),
  },
  {
    name: "LODZ",
    label: i18n.t("pages.experts_list.voivodeships.lodzkie"),
  },
  {
    name: "LUBELSKIE",
    label: i18n.t("pages.experts_list.voivodeships.lubelskie"),
  },
  {
    name: "LUBUS",
    label: i18n.t("pages.experts_list.voivodeships.lubuskie"),
  },
  {
    name: "MALOP",
    label: i18n.t("pages.experts_list.voivodeships.malopolskie"),
  },
  {
    name: "MAZOW",
    label: i18n.t("pages.experts_list.voivodeships.mazowieckie"),
  },
  {
    name: "OPOLSKIE",
    label: i18n.t("pages.experts_list.voivodeships.opolskie"),
  },
  {
    name: "PODKARP",
    label: i18n.t("pages.experts_list.voivodeships.podkarpackie"),
  },
  {
    name: "PODLASK",
    label: i18n.t("pages.experts_list.voivodeships.podlaskie"),
  },
  {
    name: "POMOR",
    label: i18n.t("pages.experts_list.voivodeships.pomorskie"),
  },
  {
    name: "SLASK",
    label: i18n.t("pages.experts_list.voivodeships.slaskie"),
  },
  {
    name: "SWIETOKRZ",
    label: i18n.t("pages.experts_list.voivodeships.swietokrzyskie"),
  },
  {
    name: "WIELKOP",
    label: i18n.t("pages.experts_list.voivodeships.wielkopolskie"),
  },
  {
    name: "ZACHODNIOP",
    label: i18n.t("pages.experts_list.voivodeships.zachodniopomorskie"),
  },
];
