import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled.div`
  padding: 15px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors["secondary-200"]};
  color: hsl(0, 0%, 100%);
`;

const CopyrightsBar = () => {
  const { t } = useTranslation();
  const getCurrentYear = ()  => {
    return new Date().getFullYear()
  }
  return <StyledWrapper>{`${getCurrentYear()} | ${t("page_footer.copyrights")}`}</StyledWrapper>;
};

export default CopyrightsBar;
