import React, {useEffect} from "react";
import Root from "templates/Root";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import heroImage from "assets/images/home_hero.jpg";

const StyledWrapper = styled.div`
  margin: 0 auto;
  min-height: 450px;
  padding: 25px;
  background-image: url(${heroImage});
  background-repeat: no-repeat;
  background-size: cover; 
`;

const StyledTitle = styled.h2`
  max-width: 500px;
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: hsl(0, 0%, 100%);
  background-color: ${({ theme }) => theme.colors.secondary};
  overflow-wrap: anywhere;
`;
const StyledText = styled.p`
  max-width: 600px;
  text-align: left;
  line-height: 1.5;
  padding: 15px 30px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: hsl(0, 0%, 100%);
  background-color: ${({ theme }) => theme.colors["secondary-100"]};
  overflow-wrap: anywhere;
`;

const Home = () => {
  useEffect(() => window.scrollTo(0,0), [])
  const { t } = useTranslation();
  return (
    <Root>
      <StyledWrapper>
        <StyledTitle>{t("pages.home.title")}</StyledTitle>
        <StyledText>{t("pages.home.text")}</StyledText>
      </StyledWrapper>
    </Root>
  );
};

export default Home;
