import React from 'react';
import styled from 'styled-components';

const StyledPageTitle = styled.h2`
  margin-bottom: 20px;
  text-align: left;
`;

const PageTitle = ({title}) => {
  return (
    <StyledPageTitle>
      {title}
    </StyledPageTitle>
  );
};

export default PageTitle;
