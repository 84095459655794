import i18n from "plugins/i18n";

export const permissionsGroup = {
  from2008: [
    {
      name: i18n.t("pages.experts_list.permissions.general_and_municipal_construction.name"),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.general_and_municipal_construction.project_group_1"
          ),
          permission_number: "1.0",
        },
      ],
    },
    {
      name: i18n.t(
        "pages.experts_list.permissions.industrial_building.name"
      ),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_1"
          ),
          permission_number: "2.1",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_2"
          ),
          permission_number: "2.2",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_3"
          ),
          permission_number: "2.3",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_4"
          ),
          permission_number: "2.4",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_5"
          ),
          permission_number: "2.5",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_6"
          ),
          permission_number: "2.6",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_7"
          ),
          permission_number: "2.7",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_8"
          ),
          permission_number: "2.8",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_9"
          ),
          permission_number: "2.9",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_10"
          ),
          permission_number: "2.10",
        },
      ],
    },
    {
      name: i18n.t(
        "pages.experts_list.permissions.agri-food_construction.name"
      ),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.agri-food_construction.project_group_1"
          ),
          permission_number: "3.0",
        },
      ],
    },
    {
      name: i18n.t(
        "pages.experts_list.permissions.specialist_construction.name"
      ),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_1"
          ),
          permission_number: "4.1",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_2"
          ),
          permission_number: "4.2",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_3"
          ),
          permission_number: "4.3",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_4"
          ),
          permission_number: "4.4",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_5"
          ),
          permission_number: "4.5",
        },
      ],
    },
  ],
  to2008: [
    {
      name: i18n.t("pages.experts_list.permissions.general_and_municipal_construction.name"),
      project_groups: [
        {
          name: [
            i18n.t(
              "pages.experts_list.permissions.general_and_municipal_construction.project_group_2"
            ),
            i18n.t(
              "pages.experts_list.permissions.general_and_municipal_construction.project_group_3"
            ),
            i18n.t(
              "pages.experts_list.permissions.general_and_municipal_construction.project_group_4"
            ),
          ],
          permission_number: "1.1",
        },
        {
          name: [
            i18n.t(
              "pages.experts_list.permissions.general_and_municipal_construction.project_group_5"
            ),
            i18n.t(
              "pages.experts_list.permissions.general_and_municipal_construction.project_group_6"
            ),
            i18n.t(
              "pages.experts_list.permissions.general_and_municipal_construction.project_group_7"
            ),
          ],
          permission_number: "1.2",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.general_and_municipal_construction.project_group_8"
          ),
          permission_number: "1.3",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.general_and_municipal_construction.project_group_9"
          ),
          permission_number: "1.4",
        },
      ],
    },
    {
      name: i18n.t(
        "pages.experts_list.permissions.industrial_building.name"
      ),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_1"
          ),
          permission_number: "2.1",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_11"
          ),
          permission_number: "2.2",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_3"
          ),
          permission_number: "2.3",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_12"
          ),
          permission_number: "2.4",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_5"
          ),
          permission_number: "2.5",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_6"
          ),
          permission_number: "2.6",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_7"
          ),
          permission_number: "2.7",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_8"
          ),
          permission_number: "2.8",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_9"
          ),
          permission_number: "2.9",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.industrial_building.project_group_13"
          ),
          permission_number: "2.10",
        },
      ],
    },
    {
      name: i18n.t(
        "pages.experts_list.permissions.agri-food_construction.name"
      ),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.agri-food_construction.project_group_2"
          ),
          permission_number: "3.1",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.agri-food_construction.project_group_3"
          ),
          permission_number: "3.2",
        },
      ],
    },
    {
      name: i18n.t(
        "pages.experts_list.permissions.specialist_construction.name"
      ),
      project_groups: [
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_1"
          ),
          permission_number: "4.1",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_6"
          ),
          permission_number: "4.2",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_7"
          ),
          permission_number: "4.3",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_8"
          ),
          permission_number: "4.4",
        },
        {
          name: i18n.t(
            "pages.experts_list.permissions.specialist_construction.project_group_9"
          ),
          permission_number: "4.5",
        },
      ],
    },
  ],
};
