import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
`;
const StyledTitle = styled.h3`
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: hsl(0, 0%, 100%);
`;

const PageFooterNavSection = ({ title, children }) => {
  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      {children}
    </StyledWrapper>
  );
};

export default PageFooterNavSection;
