import React, {useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import styled from "styled-components";
import pageLogo from "assets/images/logo.png";
import {Link, NavLink} from "react-router-dom";
import {pageNavLinks} from "constans/pageNavLinks";
import {Routes} from "constans/routes";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const StyledPageNav = styled.nav`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  margin-bottom: 5px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  z-index: 100;
`;

const StyledPageNavInner = styled.div`
position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
`;

const StyledPageLogo = styled.img`
  width: 120px;
`;

const StyledPageNavList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 14%),
    0 0px 0px 0 rgb(0 0 0 / 12%);
  overflow: hidden;
  ${({ isOpen }) =>
    isOpen &&
    `
   border-top: 1px solid hsl(0, 0%, 85%);
   animation: openMenu 0.3s both;
  `};

  ${({ isOpen }) =>
    !isOpen &&
    `
   animation: closeMenu 0.3s both;
  `};
  @keyframes openMenu {
    from {
      height: 0;
    }

    to {
      height: 246px;
    }
  }

  @keyframes closeMenu {
    from {
      height: 246px;
    }

    to {
      height: 0;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    position: initial;
    width: initial;
    border-top: none;
    box-shadow: none;
    animation: none;
  }
`;

const StyledPageNavListItem = styled.li`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 8px;
  &:not(:last-child):after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 1px;
    background-color: hsl(0, 0%, 85%);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: initial;
    &:not(:last-child):after {
      display: none;
    }
  }
`;

const StyledPageNavListItemLink = styled(NavLink)`
  width: 100%;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
    width: initial;
  }
`;

const StyledMenuButton = styled.button`
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none !important;
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const PageNav = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const handleMenuToggleClick = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <StyledPageNav>
      <StyledPageNavInner>
        <Link to={Routes.HOME}>
          <StyledPageLogo src={pageLogo} alt={t("page_nav.logo_alt")} />
        </Link>
        <StyledPageNavList isOpen={isOpen}>
          {pageNavLinks.map((item) => (
            <StyledPageNavListItem key={item.name}>
              <StyledPageNavListItemLink
                to={item.path}
                activeClassName="active"
              >
                {item.name}
              </StyledPageNavListItemLink>
            </StyledPageNavListItem>
          ))}
        </StyledPageNavList>
        <StyledMenuButton type="button" onClick={handleMenuToggleClick}>
          {!isOpen ? (
            <StyledMenuIcon color="primary" />
          ) : (
            <CloseIcon color="primary" />
          )}
        </StyledMenuButton>
      </StyledPageNavInner>
    </StyledPageNav>
  );
};

export default withTranslation()(PageNav);
