import React, {useEffect} from "react";
import Root from "templates/Root";
import PageTitle from "components/PageTitle";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import aboutImage from "assets/images/about-hero.jpeg";

const StyledWrapper = styled.div`
  margin: 0 30px;
`;

const StyledParagraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    flex-basis: 50%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  padding: 20px;
  line-height: 1.5;
  font-weight: 500;
  overflow-wrap: anywhere;
`;

const StyledAboutImage = styled.img`
  max-width: 100%;
  order: -1;
  margin-bottom: 20px;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    order: initial;
    margin-bottom: 0;
    margin-left: 40px;
  }
`;

const About = () => {
  useEffect(() => window.scrollTo(0,0), [])
  const { t } = useTranslation();
  return (
    <Root>
      <StyledWrapper>
        <PageTitle title={t("page_nav.about")} />
        <StyledContentWrapper>
          <StyledCard>
            <StyledParagraph>{t("pages.about.paragraph_1")}</StyledParagraph>
            <StyledParagraph>{t("pages.about.paragraph_2")}</StyledParagraph>
            <StyledParagraph>{t("pages.about.paragraph_3")}</StyledParagraph>
          </StyledCard>
          <StyledAboutImage src={aboutImage} />
        </StyledContentWrapper>
      </StyledWrapper>
    </Root>
  );
};

export default About;
