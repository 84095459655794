import React from "react";
import GoogleMapReact from 'google-map-react';

const COORDINATES = {lat: 52.5249458, lng: 19.7847041};
const API_KEY = 'AIzaSyCjeQD3_DaIUWwNgPDa8nXTWaQTIyLYmuM';

const LocationMap = () => {

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={COORDINATES}
        defaultZoom={17}
      >
      </GoogleMapReact>
    </div>
  );
};

export default LocationMap;
