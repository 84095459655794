import React from "react";
import PageNav from "components/PageNav";
import styled from "styled-components";
import PageFooter from "components/PageFooter";

const StyledWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledMainSection = styled.main`
  flex-grow: 1;
  display: flex;
  margin-top: 96px;
  background-color: hsl(0,0%,94%);
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
`;

const Root = ({ children }) => {
  return (
    <StyledWrapper>
      <PageNav />
      <StyledMainSection>
        <StyledContentWrapper>{children}</StyledContentWrapper>
      </StyledMainSection>
      <PageFooter />
    </StyledWrapper>
  );
};

export default Root;
