import React from 'react';
import styled from 'styled-components';
import PageFooterNav from "components/PageFooterNav";
import CopyrightsBar from "components/CopyrightsBar";

const StyledWrapper = styled.div`
display: flex;
flex-direction: column;
`;

const PageFooter = () => {
  return (
    <StyledWrapper>
      <PageFooterNav/>
      <CopyrightsBar/>
    </StyledWrapper>
  );
};

export default PageFooter;
