import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Routes } from "constans/routes";
import Root from "templates/Root";
import NotFoundImage from "assets/images/404-image.jpg";

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  
  }
`;
const StyledImage = styled.img`
  width: 300px;
  border-radius: 30px;
  margin-bottom: 20px;
   @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({theme}) => theme.colors.primary};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: flex-start;
  
  }
`;

const StyledTitle = styled.h2`
display: flex;
flex-direction: column;
align-items: center;
 margin-bottom: 10px;
 text-align: center;
 @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: flex-start;
  
  }
`;

const StyledLink = styled(Link)`
font-weight: ${({theme}) => theme.fontWeights.bold};
color: ${({theme}) => theme.colors.secondary};
`;

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <StyledWrapper>
        <StyledImage
          src={NotFoundImage}
          alt={t("pages.not_found.not_found_image_alt")}
        />
        <ContentWrapper>
          <StyledTitle>
            {t("pages.not_found.something_went_wrong")}{" "}
            <span>{t("pages.not_found.not_found_this_page")}</span>
          </StyledTitle>
          <StyledLink to={Routes.HOME}>
            {t("pages.not_found.get_me_out_of_here")}
          </StyledLink>
        </ContentWrapper>
      </StyledWrapper>
    </Root>
  );
};

export default NotFound;
