import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider as MaterialThemeProvider} from "@material-ui/styles";
import {createGlobalStyle, ThemeProvider as StyledComponentsThemeProvider} from "styled-components";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Home from "pages/Home";
import News from "pages/News";
import About from "pages/About";
import ExpertsList from "pages/ExpertsList";
import {Routes} from "constans/routes";
import Contact from "pages/Contact";
import NotFound from "pages/NotFound";
import { createBrowserHistory } from "history";

function App() {
  const GlobalStyle = createGlobalStyle`
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
min-height: 100vh;
}
`;

  const materialTheme = createMuiTheme({
    palette: {
      primary: {
        main: "hsl(0,0%,22%)",
      },
      secondary: {
        main: "hsl(197,100%,44%)",
      },
    },
  });

  const styledComponentsTheme = {
    colors: {
      primary: "hsl(0,0%,22%)",
      secondary: "hsl(197,100%,44%)",
      'secondary-100': 'hsl(198,54%,45%)',
      'secondary-200': 'hsl(0,0%,12%)',
      title: "hsl(0,0%,60%)",
      text: "hsl(329,1%,76%)",
    },
    fontSizes: {
      small: "1rem",
      medium: "2rem",
      large: "3rem",
    },
    fontWeights: {
      light: 200,
      normal: 400,
      medium: 500,
      bold: 600,
    },
    breakpoints: {
      mobile: "480px",
      tablet: "768px",
      laptop: "1024px"
    }
  };
  const history = createBrowserHistory();
  return (
        <StyledComponentsThemeProvider theme={styledComponentsTheme}>
          <GlobalStyle />
          <MaterialThemeProvider theme={materialTheme}>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Redirect to={Routes.HOME} />;
                  }}
                />
                <Route path={Routes.HOME} component={Home} exact />
                <Route path={Routes.NEWS} component={News} exact />
                <Route path={Routes.ABOUT} component={About} exact />
                <Route
                  path={Routes.EXPERTS_LIST}
                  component={ExpertsList}
                  exact
                />
                <Route path={Routes.CONTACT} component={Contact} exact />
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Router>
          </MaterialThemeProvider>
        </StyledComponentsThemeProvider>
  );
}

export default App;
