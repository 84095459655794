import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PageFooterNavSection from "components/PageFooterNavSection";
import { pageNavLinks } from "constans/pageNavLinks";
import { NavLink } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import { renderHrefAttributeValue } from "helpers/renderRedirectLink";

const StyledWrapper = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const StyledWrapperInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    text-align: left;
  }
`;

const PageFooterNavLink = styled(NavLink)`
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.text};
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledPageFooterContactListItem = styled.li`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.text};
`;

const StyledFooterContactListItemLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
`;

const StyledList = styled.ul`
  list-style-type: none;
`;

const FACEBOOK_LINK = 'https://www.facebook.com/Polskie-Stowarzyszenie-Rzeczoznawc%C3%B3w-BHP-109138048059107';

const PageFooterNav = () => {
  const { t } = useTranslation();
  const pageFooterContactList = [
    {
      name: `${t("page_footer.address")}:`,
      value: "ul. Parcele 57, 09-408 Płock",
      isLink: true,
      link:
        "https://www.google.com/maps/place/Parcele+57,+09-408+P%C5%82ock/@52.5249458,19.7847041,17z/data=!3m1!4b1!4m5!3m4!1s0x471c7169fafaaf87:0x423e6d6592506264!8m2!3d52.5249458!4d19.7868928",
    },
    {
      name: `${t("page_footer.email")}:`,
      value: "psrbhp@psrbhp.pl",
      isLink: true,
      linkPrefix: "mailto:",
    },
    {
      name: `${t("page_footer.tel")}.:`,
      value: "+48 502 382 307",
      isLink: true,
      linkPrefix: "tel:",
    },
    {
      name: `${t("page_footer.account")}:`,
      value: "75 1240 3174 1111 0010 4041 9207",
    },
    { name: `${t("page_footer.regon")}:`, value: "143014847" },
    { name: `${t("page_footer.nip")}:`, value: "7743203773" },
  ];

  return (
    <StyledWrapper>
      <StyledWrapperInner>
        <PageFooterNavSection title={t("page_footer.menu")}>
          <StyledList>
            {pageNavLinks.map((item) => (
              <li key={item.name}>
                <PageFooterNavLink to={item.path} activeClassName="active">
                  {item.name}
                </PageFooterNavLink>
              </li>
            ))}
          </StyledList>
        </PageFooterNavSection>
        <PageFooterNavSection title={t("page_footer.contact")}>
          <StyledList>
            {pageFooterContactList.map((item) => (
              <StyledPageFooterContactListItem key={item.name}>
                {item.name}{" "}
                {item.isLink ? (
                  <StyledFooterContactListItemLink
                    href={renderHrefAttributeValue(item)}
                  >
                    {item.value}
                  </StyledFooterContactListItemLink>
                ) : (
                  item.value
                )}
              </StyledPageFooterContactListItem>
            ))}
          </StyledList>
        </PageFooterNavSection>
        <PageFooterNavSection title={t("page_footer.follow_us")}>
          <a href={FACEBOOK_LINK}>
            <FacebookIcon fontSize="large" color="secondary" />
          </a>
        </PageFooterNavSection>
      </StyledWrapperInner>
    </StyledWrapper>
  );
};

export default PageFooterNav;
